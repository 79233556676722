// @ts-ignore
import { graphql, useStaticQuery } from "gatsby";
import React, { useEffect, useState } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { ParallaxBanner } from "react-scroll-parallax";
import { Controller, Scene } from "react-scrollmagic";
import SEO from "../../../components/shared/seo";
import LayoutCaseStudy from "../../../components/layouts/layout-case-study";
import { OverlayFillGradient } from "../../../components/shared/overlay";
import { FadeUpBox, FadeIn } from "../../../components/shared/transitions";
import { CaseStudyNav } from "../../../components/shared/case-study-nav";
import { QuoteBlock } from "../../../components/shared/quote-block";
import { Helmet } from "react-helmet";

function SJBeatsPage({ data }) {
  const [animate, setAnimate] = useState(false);

  const stJohnImages = useStaticQuery(graphql`
    query {
      stjohnMockup: file(relativePath: { eq: "stjohn-mac-mockup.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      stjohnHomeBanner: file(relativePath: { eq: "st-john-banner.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      stjohnExamples: file(relativePath: { eq: "stjohn-mockup3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 70) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      prevWorkImage: file(relativePath: { eq: "wes-banner.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 70) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      stJohnLogo: file(relativePath: { eq: "st-john-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 200, quality: 70) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      nextWorkImage: file(relativePath: { eq: "murdoch-hero.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 70) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      beatsMacMockup: file(relativePath: { eq: "beat-mac-pro.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      beatsDecorative: file(relativePath: { eq: "beats-decorative.png" }) {
        childImageSharp {
          fluid(maxWidth: 980, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      sitefinityLogo: file(relativePath: { eq: "sitefinity-logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimate(true);
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  });

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {`
          {
            "@context": "http://schema.org",
            "@type": "Organization",
            "name": "Diviv Group",
            "description": ".NET applications, Progress Sitefinity CMS, Drupal, GovCMS, Sitecore Experience Platform, Microsoft Sharepoint, Dynamics CRM and Azure Hosting support in Perth Australia. Coupled with our creative and user experience team we are able to provide expert consultation and resources on a wide range of digital development projects across many industries.",
            "url": "https://www.diviv.com.au",
            "image": "https://www.diviv.com.au/icons/icon-96x96.png",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "165 / 15 Aberdeen St",
              "addressLocality": "Perth",
              "addressRegion": "WA",
              "postalCode": "6000",
              "addressCountry": "AU"
            },
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "5",
              "ratingCount": "1"
            },
            "review": {
              "@type": "Review",
              "author": "StJohn WA",
              "datePublished": "2020-03-09",
              "reviewBody": "We have been working with Diviv for around 4 months now and have been very pleased with how they operate. Biggest task to date has been a new brand campaign which meant a new look website. The guys delivered on time and on budget which is what you need when the pressure is on. We hope to continue to work with them for many more years.",
              "reviewRating": {
                "@type": "Rating",
                "bestRating": "5",
                "ratingValue": "5",
                "worstRating": "1"
              }
            }
          }
          `}
        </script>
      </Helmet>
      <SEO title="St John Ambulance" />
      <LayoutCaseStudy>
        <div
          id="beats-internal-content"
          className="page-layout"
          data-menu-class="inverted"
        >
          <Container>
            <Row>
              <Col sm={12} lg={8}>
                <div className="client-logo--lg mb-4">
                  <Image
                    src={stJohnImages.stJohnLogo.childImageSharp.fluid.src}
                  />
                </div>
                <FadeUpBox pose={animate ? "enter" : "exit"}>
                  <FadeIn>
                    <h6>Web App Development</h6>
                  </FadeIn>
                  <FadeIn duration={200}>
                    <h2>BEATS Internal News Site</h2>

                    <p>
                      St John Ambulance Australia have over 1,500 paid employees
                      and over 9,140 volunteers. In 2019 Diviv helped their
                      corporate communications team by creating Beats, a
                      scalable communications platform to engage their
                      workforce.
                    </p>
                  </FadeIn>
                </FadeUpBox>
              </Col>

              <Col sm={12} lg={4}>
                <FadeUpBox pose={animate ? "enter" : "exit"}>
                  <FadeIn>
                    <div className="client-meta">
                      Discover what else St John is up to by visiting their
                      website:
                      <div className="client-meta__link">
                        <a href="https://stjohnwa.com.au/" target="_blank">
                          St John
                        </a>
                      </div>
                    </div>
                  </FadeIn>
                </FadeUpBox>
              </Col>
            </Row>
          </Container>

          <Controller>
            <Scene
              triggerElement="#intro-section01"
              reverse={false}
              duration={2000}
              offset={200}
            >
              {(progress, event) => (
                <section className="image-mockup-section" id="intro-section01">
                  <Container>
                    <Row className="intro-section__image-mock-up">
                      <Col lg={12} sm={12}>
                        <FadeUpBox pose={progress > 0 ? "enter" : "exit"}>
                          <FadeIn duration={200}>
                            <Image
                              src={
                                stJohnImages.beatsMacMockup.childImageSharp
                                  .fluid.src
                              }
                            />
                          </FadeIn>
                        </FadeUpBox>
                      </Col>
                    </Row>
                  </Container>
                </section>
              )}
            </Scene>

            <Scene
              triggerElement="#intro-section02"
              reverse={false}
              duration={2000}
              offset={200}
            >
              {(progress, event) => (
                <section id="intro-section02">
                  <Container>
                    <Row>
                      <Col lg={6} sm={12} className="text-section">
                        <FadeUpBox pose={progress > 0 ? "enter" : "exit"}>
                          <FadeIn>
                            <div className="mb-5">
                              <h4>The Problem</h4>
                              <p>
                                St John were sending out a bi-monthly newsletter
                                to their 5000 fulltime and volunteer employees.
                                This required lengthy editorial time working
                                through the staff generated news stories
                                communicated over email not to mention the
                                production costs of designing a layout which was
                                then delivered in a PDF format.
                              </p>
                              <p>
                                Based on email analytics their engagement levels
                                were extremely low, it was clear the corporate
                                communications team required a simpler way to
                                reach their staff and one that didn’t require
                                the same time costs as the PDF and email
                                solution.
                              </p>
                            </div>

                            <h4>The Solution</h4>
                          </FadeIn>
                          <FadeIn>
                            <p>
                              Our team presented the idea of using a web
                              application / microsite to present their
                              bi-monthly newsletter releases allowing the use of
                              multimedia (Video, Audio, Podcasts etc.). Our
                              creative team suggested the use of a news upload
                              form which automatically put news items into a
                              draft format along with required fields ready for
                              easy editorial modification and approval.
                            </p>
                            <p>
                              Diviv also built a managerial preview which allows
                              bi-monthly releases to be reviewed and approved
                              before the next release was published. Our team
                              also used category tagging and built custom
                              personalised suggestions based on any of the
                              articles being viewed and presented them in the
                              right-hand margin for ease of navigation.
                            </p>
                          </FadeIn>
                        </FadeUpBox>
                      </Col>

                      <Col lg={6} sm={12}>
                        <div className="tagline">
                          <FadeUpBox pose={progress > 0 ? "enter" : "exit"}>
                            <FadeIn>
                              Oh, that feeling you get when you interact with a
                              great digital product!
                            </FadeIn>
                          </FadeUpBox>
                        </div>

                        <div className="img-wrapper">
                          <FadeUpBox pose={progress > 0 ? "enter" : "exit"}>
                            <FadeIn>
                              <Image
                                src={
                                  stJohnImages.beatsDecorative.childImageSharp
                                    .fluid.src
                                }
                              />
                            </FadeIn>
                          </FadeUpBox>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </section>
              )}
            </Scene>
          </Controller>
        </div>

        <Scene
          triggerElement="#intro-section"
          reverse={false}
          duration={2000}
          offset={200}
        >
          {(progress, event) => (
            <section className="quote-wrapper">
              <Container>
                <QuoteBlock
                  author="StJohn WA"
                  authorPosition="Corporate Affairs"
                  quoteText="We have been working with Diviv for around 4 months
                             now and have been very pleased with how they operate. 
                             Biggest task to date has been a new brand campaign which 
                             meant a new look website. The guys delivered on time and 
                             on budget which is what you need when the pressure is on. 
                             We hope to continue to work with them for many more years."
                  clientLogoUrl={stJohnImages.stJohnLogo.childImageSharp.fluid.src}
                />
              </Container>
            </section>
          )}
        </Scene>

        <CaseStudyNav
          nextUrl="/our-work/murdoch-university"
          nextImage={stJohnImages.nextWorkImage.childImageSharp.fluid.src}
          nextTitle="Murdoch University"
        />
      </LayoutCaseStudy>
    </>
  );
}

export default SJBeatsPage;
